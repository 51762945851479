<template>
	<div>
		<div class="policy">
			<div class="wrapper">
				<div class="container-fluid-inner">
					<img class="detail_picture" border="0" src="/igroteka.jpg" width="940" height="250" alt="ИгрО!тека" title="ИгрО!тека">
					<h1>ИгрО!тека</h1>
					<div class="container-descr">
						<br>
						<h5>
							<p>
								<b>Игры на любой вкус! Для&nbsp;телефонов, планшетов, ноутбуков и компьютеров</b>
							</p>
							<b></b>
						</h5>
						<p>
							Вас ждет многотысячная лицензионная коллекция онлайн-игр различных жанров: симуляторы, гонки, квесты, детские, RPG, викторины, стратегии, логические, космические и другие. С сервисом "ИгрО!тека" вы получаете:
						</p>
						<ul>
							<li>Первые 3 дня бесплатно (при первом подключении);</li>
							<li>Бесплатный интернет-трафик при пользовании порталом;</li>
							<li>Постоянное обновление каталогов игр и приложений;</li>
							<li>Возможность загружать и устанавливать до 3-х игр ежедневно;</li>
							<li>Возможность играть онлайн без ограничений на любом устройстве.</li>
						</ul>
						<p>
						</p>
						<b>Подключение:</b>
						0 сомов
						<b><br>
						</b>
						<b>Абонентская плата в сутки:</b>
						6 сомов (с учётом НДС без учета НсП)<br>
						<b>Команда подключения:</b>
						<i>
							<span class="icons"> </span>
						</i>
						<span class="icons">*4546#</span>
						<span class="icons icons--phone"></span>
						<br></div>
					<div class="newtabs-list">
						<ul class="newtabs-links">
							<li class="newtabs-link newtabs-link-active" id="newtab1-link">Подробная информация</li>

						</ul>
						<div class="newtabs-content" id="newtab1" style="">
							<ul style="text-align: justify;">
								<li> Активируйте услугу, когда вы захотите играть онлайн. Для активации сервиса "ИгрО!тека" необходимо набрать команду: *4546#. После активации сервиса вам будет отправлено
									 SMS-сообщение с четырёхзначным паролем, которым вы можете пользоваться при посещении портала;
								</li>
								<li>Если вы забыли пароль, можете набрать: *4546*1#. Пароль будет повторно отправлен на ваш мобильный телефон;</li>
								<li>Возможность пользования порталом бесплатно в течение 3-х дней предоставляется только при первом подключении к сервису;</li>
								<li>Портал «ИгрО!тека» доступен для всех абонентов О! за исключением тех, кто обслуживается на тарифных планах «Бишкекский», «Интернет+», «Интернет+ для корпоративных абонентов», «Алга Интернет + О!TV».</li>
								<li> Если на вашем мобильном счёте не будет достаточно средств для списания ежедневной абонентской платы, действие сервиса «ИгрО!тека» будет приостановлено. Необходимо просто пополнить баланс на сумму, достаточную для списания абонплаты и продолжить
									 пользоваться сервисом.
								</li>
							</ul>
						</div>
					</div>
					<div class="newaccordeon-list">
						<div class="newaccordeon-items">
							<div class="newaccordeon-content" style="display: none">
								<ul style="text-align: justify;">
									<li> Чтобы играть, посетите сайт
										<a href="http://game.o.kg/">game.o.kg</a>. Активируйте услугу, когда вы захотите играть онлайн. Для активации сервиса "ИгрО!тека" необходимо набрать команду: *4546#. После активации сервиса вам будет отправлено
										 SMS-сообщение с четырёхзначным паролем, которым вы можете пользоваться при посещении портала;
									</li>
									<li>Если вы забыли пароль, можете набрать: *4546*1#. Пароль будет повторно отправлен на ваш мобильный телефон;</li>
									<li>Возможность пользования порталом бесплатно в течение 3-х дней предоставляется только при первом подключении к сервису;</li>
									<li>Портал «ИгрО!тека» доступен для всех абонентов О! за исключением тех, кто обслуживается на тарифных планах «Бишкекский», «Интернет+», «Интернет+ для корпоративных абонентов», «Алга Интернет + О!TV».</li>
									<li> Если на вашем мобильном счёте не будет достаточно средств для списания ежедневной абонентской платы, действие сервиса «ИгрО!тека» будет приостановлено. Необходимо просто пополнить баланс на сумму, достаточную для списания абонплаты и продолжить
										 пользоваться сервисом.
									</li>
									<li> Больше информации об услуге «ИгрО!тека» на сайте game.o.kg или по телефону 760 (звонок для абонентов О! бесплатный).</li>
									<li>Отключение *4546*0#.</li>
									<p>
									</p>
								</ul>
							</div>
						</div>
						<div class="newaccordeon-items">
							<div class="newaccordeon-content" style="display: none"></div>
						</div>
						<div class="newaccordeon-items">
							<div class="newaccordeon-content" style="display: none"></div>
						</div>
						<div class="newaccordeon-items">
							<div class="newaccordeon-content" style="display: none"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
