import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '@/views/HomeView';
import PlatformView from '@/views/PlatformView';
import GameView from '@/views/GameView';
import GamePlayView from '@/views/GamePlayView';
import ProfileView from '@/views/ProfileView';
import TagView from "@/views/TagView";
import SubscribeView from "@/views/SubscribeView";
import Login from "@/views/Login";
import Policy from "@/views/Policy";

Vue.use(VueRouter);

const routes = [
	// Home
	{path: '/platform/:platform', name: 'platform', component: PlatformView},
	{path: '/tag/:tag', name: 'tag', component: TagView},
	{path: '/game/:id', name: 'game', component: GameView},
	{path: '/play/:id', name: 'play', component: GamePlayView},
	{path: '/profile', name: 'profile', component: ProfileView},
	{path: '/subscribe', name: 'subscribe', component: SubscribeView},
	{path: '/login', name: 'login', component: Login},
	{path: '/info', name: 'policy', component: Policy},
	{path: '/', name: 'home', component: HomeView},
];

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes,
	mode: 'history'
});

router.beforeEach((to, from, next) => {
	axios({url: '/a1/profile', baseURL: '/'}).then(({data}) => {
		window.auth.msisdn = data.msisdn;
		window.auth.subscribtion = data.status === 'subscribed' || data.status === 'allowed';
		
		next();
	});
});

export default router;
