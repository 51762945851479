<template>
	<div class="footer">
		<div class="wrapper">
			<div class="footer_row">
				<div class="footer_copy">© Tcell 2020</div>
				<a href="/oferta.pdf" class="footer_link">Условия сервиса</a>
				<a @click.prevent="unsubscribe" v-if="auth.subscribed" href="" class="footer_btn">Отписаться</a>
			</div>
		</div>

		<div class="login-form" v-if="showForm">
			<div class="login-form_text">
				Подписка на игровой портал успешно отключена. Для повторной активации наберите со своего мобильного устройства *1191*6#
			</div>
		</div>

		<div v-if="showForm" class="login-form-layout" @click="hideForm"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			auth: window.auth,
			showForm: false
		}
	},

	methods: {
		hideForm() {
			this.showForm = false;
		},
		unsubscribe() {
			this.axios({url: '/a1/unsubscribe', baseURL: '/'}).then(() => {
				window.auth.subscribed = false;
				this.showForm = true;
			})
		}
	}
}
</script>

<style scoped>

</style>
